export const Play = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      version="1.1"
      width="44"
      height="44"
      viewBox="0 0 44 44"
      {...props}
    >
      <defs>
        <clipPath id="master_svg0_411_55568">
          <rect x="0" y="0" width="44" height="44" rx="0" />
        </clipPath>
      </defs>
      <g clipPath="url(#master_svg0_411_55568)">
        <g>
          <path
            d="M22,2.75C32.614999999999995,2.75,41.25,11.385,41.25,22C41.25,32.614999999999995,32.614999999999995,41.25,22,41.25C11.385,41.25,2.75,32.614999999999995,2.75,22C2.75,11.385,11.385,2.75,22,2.75ZM22,4.58333C12.397,4.58333,4.58333,12.397,4.58333,22C4.58333,31.603,12.397,39.4167,22,39.4167C31.603,39.4167,39.4167,31.603,39.4167,22C39.4167,12.397,31.603,4.58333,22,4.58333ZM17.7467,12.53267C18.369999999999997,12.53267,18.9842,12.70317,19.5158,13.0277L29.4855,19.1015C31.6614,20.4221,31.6614,23.5797,29.4855,24.9003L19.5158,30.9723C18.467399999999998,31.6092,17.1572,31.6313,16.0878,31.0304C15.0185,30.4294,14.3561,29.2986,14.355,28.072L14.355,15.928C14.355,14.0525,15.8748,12.53267,17.7467,12.53267Z"
            fill="#000000"
            fillOpacity="1"
          />
        </g>
      </g>
    </svg>
  )
}
