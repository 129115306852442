export const Password = (props: any) => {
  return (
    <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" {...props}>
      <path
        d="M851.450696 21.647662l-399.545757 399.609756c-117.438165-55.551132-262.011906-35.007453-359.162388 62.143029-123.646068 123.646068-123.646068 324.218934 0 447.865002s324.218934 123.646068 447.865002 0c97.150482-97.150482 117.630162-241.660224 62.143029-359.226387l151.741629-151.741629 96.382494 96.382494c28.79955 28.79955 75.51882 28.863549 104.382369 0l43.51932-43.51932c28.79955-28.79955 28.863549-75.51882 0-104.382369l-54.463149-54.463149 0.063999-0.063999-41.983344-41.791347 99.966438-99.966438c28.79955-28.79955 28.863549-75.51882 0-104.382369l-46.463274-46.463274c-28.863549-28.863549-75.582819-28.863549-104.446368 0zM392.065874 782.787769c-41.663349 41.663349-109.182294 41.59935-150.781644-0.063999-41.663349-41.663349-41.663349-109.182294 0-150.845643s109.182294-41.59935 150.781644 0.063999 41.727348 109.118295 0 150.845643z"
        fill="#444444"
      ></path>
    </svg>
  )
}
