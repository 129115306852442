export const Loginout = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      version="1.1"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...props}
    >
      <defs>
        <clipPath id="master_svg0_411_24117">
          <rect x="0" y="0" width="16" height="16" rx="0" />
        </clipPath>
      </defs>
      <g clipPath="url(#master_svg0_411_24117)">
        <g>
          <path
            d="M8,0C3.58172,0,0,3.58172,0,8C0,12.4183,3.58172,16,8,16C12.4183,16,16,12.4183,16,8C16,3.58172,12.4183,0,8,0ZM9.50667,8.4L7.38667,10.52C7.16764,10.7394,6.81222,10.7395,6.59303,10.5203C6.37383,10.3011,6.37397,9.94569,6.59333,9.72667L7.75333,8.56667L0.893333,8.56667C0.582213,8.56667,0.33,8.31445,0.33,8.00333C0.33,7.69221,0.582213,7.44,0.893333,7.44L7.74667,7.44L6.58667,6.27333C6.3673,6.05431,6.36717,5.69889,6.58636,5.47969C6.80555,5.2605,7.16098,5.26064,7.38,5.48L9.5,7.6C9.72037,7.82114,9.72037,8.17886,9.5,8.4L9.50667,8.4Z"
            fill="#2C2C2C"
            fillOpacity="1"
          />
        </g>
      </g>
    </svg>
  )
}
