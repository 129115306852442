import './style.scss'
import { FC, memo } from 'react'
import { UserStore } from '@/global-states'
import MobileLogin from './components/mobie-login'
import PasswordLogin from './components/password-login'
import { LoginStore } from './states'

const Login: FC = () => {
  const { loginType } = LoginStore
  const { background_image, logo, welcome_line, icp_filing_no } = UserStore.config

  return (
    <div className="page-login">
      <div className="page-login-main">
        <div className="left">
          <img src={background_image} />
        </div>
        <div className="right">
          <img className="logo" src={logo} />
          <div className="title">{welcome_line}</div>
          <div className="login-box">{loginType === 'mobile' ? <MobileLogin /> : <PasswordLogin />}</div>
          {icp_filing_no?.trim() && (
            <div className="icp-box">
              <a href="https://beian.miit.gov.cn" target="_blank" rel="noreferrer">
                {icp_filing_no}
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default memo(Login)
