import './style.scss'
import { Button, Form, FormProps, Input, message } from 'antd'
import { FC, memo, useEffect } from 'react'
import { eventTracking } from '@/libs/util'
import * as Api from '@/api/account'
import { UserStore } from '@/global-states'

const ResetPassword: FC = () => {
  const { name } = UserStore.config
  type FieldType = {
    oldPassword?: string
    newPassword?: string
    newPassword2?: string
  }
  const onFinish: FormProps<FieldType>['onFinish'] = async (values) => {
    if (values.newPassword !== values.newPassword2) {
      message.error('两次密码不一致')
      return
    }

    try {
      await Api.resetPassword({
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
        system: name
      })
      message.success('密码修改成功')
    } catch (error: any) {
      console.log(error)
    }
  }

  useEffect(() => {
    eventTracking('ResetPasswordPage')
  }, [])

  return (
    <div className="page-setting">
      <div style={{ width: '100%' }}>
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 8 }}
          style={{ maxWidth: 600, marginTop: 60 }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item<FieldType> label="旧密码" name="oldPassword" rules={[{ required: true, message: '请输入旧密码' }]}>
            <Input.Password />
          </Form.Item>
          <Form.Item<FieldType>
            label="新密码"
            name="newPassword"
            rules={[{ required: true, message: '请输入新密码，6位以上且包含数字、字母' }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item<FieldType>
            label="重复新密码"
            name="newPassword2"
            rules={[{ required: true, message: '请重复输入新密码' }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 8 }}>
            <Button type="primary" htmlType="submit">
              提交
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default memo(ResetPassword)
