export const Agreement = (props: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" version="1.1" width="14" height="14" {...props}>
      <path
        d="M870.257507 102.444998H656.367951C634.769005 43.047898 578.521752 0 511.925004 0s-122.844002 43.047898-144.442947 102.444998H153.5925C97.195254 102.444998 51.147503 148.492749 51.147503 204.740003v716.814999c0 56.247254 46.047752 102.444998 102.444997 102.444998h716.815c56.247254 0 102.444998-46.047752 102.444997-102.444998V204.740003c-0.149993-56.247254-46.197744-102.295005-102.59499-102.295005z m-358.332503 0c28.198623 0 51.147503 23.098872 51.147502 51.147502 0 28.198623-23.098872 51.147503-51.147502 51.147503-28.198623 0-51.147503-23.098872-51.147503-51.147503 0-28.198623 22.948879-51.147503 51.147503-51.147502z m102.444997 716.665006h-358.482496v-102.444997h358.332504v102.444997h0.149992z m153.592501-204.740003H255.887505v-102.444997h511.925004v102.444997h0.149993z m0-204.740002H255.887505v-102.444998h511.925004v102.444998h0.149993z"
        fill="#272536"
      ></path>
    </svg>
  )
}
