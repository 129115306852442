import './style.scss'
import { FC, memo, PropsWithChildren } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Home, Video } from '@/assets/svg'
import { User } from '@/assets/svg/user'
import { UserStore } from '@/global-states'
import { CopyrightCircleFilled } from '@ant-design/icons'

const LayoutMenu: FC = () => {
  const { pathname } = useLocation()
  const { promote_image } = UserStore.config

  const Item: FC<PropsWithChildren<{ current: string; path: string }>> = (props) => {
    const { children, current, path } = props

    return (
      <Link to={`../${path}`} className="menu-link">
        <div className={`panel ${current === path ? 'selected' : ''}`}>{children}</div>
      </Link>
    )
  }

  return (
    <div className="layout-menu">
      <div className="layout-menu-main">
        <Item current={pathname} path="/home">
          <Home className="icon" />
          数字分身
        </Item>
        <Item current={pathname} path="/clone">
          <CopyrightCircleFilled className="clone" />
          声音克隆
        </Item>
        <Item current={pathname} path="/video">
          <Video className="icon" />
          作品管理
        </Item>
        <Item current={pathname} path="/setting">
          <User className="icon" />
          个人中心
        </Item>
      </div>

      <div className="bottom">
        <img className="qrcode" src={promote_image} />
      </div>
    </div>
  )
}

export default memo(LayoutMenu)
