export const CreateVideo = (props: any) => {
  return (
    <svg
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 48 49"
      {...props}
    >
      <g>
        <path d="M20.771 37.478c.19.13.323.362.589.828l.42.737c.268.467.401.7.416.93a1 1 0 0 1-.172.63c-.13.19-.364.324-.83.59-4.658 2.657-6.987 3.986-8.898 3.788a5.929 5.929 0 0 1-4.167-2.377C7 41.068 7 38.41 7 33.097V16.903c0-5.314 0-7.97 1.13-9.507a5.93 5.93 0 0 1 4.166-2.377c1.91-.198 4.24 1.13 8.898 3.788l14.193 8.096c4.658 2.657 6.987 3.986 7.768 5.72a5.78 5.78 0 0 1 0 4.754c-.427.948-1.317 1.775-2.797 2.764-.442.296-.664.444-.908.47a1.027 1.027 0 0 1-.618-.135c-.212-.126-.358-.363-.65-.839l-.419-.68c-.293-.476-.44-.714-.462-.946a.967.967 0 0 1 .126-.587c.114-.203.36-.37.851-.705l.157-.108c.528-.369.811-.619.956-.763.01-.01.016-.016.06-.075.019-.026.087-.138.1-.167a.582.582 0 0 0 .038-.086c.104-.34.104-.7 0-1.04a.582.582 0 0 0-.038-.086 2.018 2.018 0 0 0-.1-.167c-.044-.06-.05-.064-.06-.075-.145-.144-.428-.394-.956-.763-1.077-.752-2.633-1.644-5.03-3.011L19.212 12.28c-2.397-1.367-3.958-2.253-5.156-2.799-.642-.293-1.018-.408-1.21-.453l-.07-.008a2.108 2.108 0 0 0-.171-.003c-.064.005-.07.006-.08.008-.4.08-.759.28-1.027.565a.57.57 0 0 0-.057.076 2.02 2.02 0 0 0-.096.17.581.581 0 0 0-.035.087c-.053.194-.128.556-.186 1.179-.12 1.291-.124 3.063-.124 5.8v16.194c0 2.737.004 4.509.124 5.8.058.623.133.985.186 1.179.004.013.006.02.035.087.013.03.078.144.096.17.042.06.047.066.057.076.268.284.626.485 1.027.565.01.002.016.003.08.008.027.002.144 0 .17-.003l.071-.008c.192-.045.568-.16 1.21-.453 1.198-.546 2.759-1.432 5.156-2.799.465-.266.698-.399.928-.414a1 1 0 0 1 .631.173Z"></path>
        <path d="M32.828 45a1 1 0 0 0 1-1v-5h5a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1h-5v-5a1 1 0 0 0-1-1h-2a1 1 0 0 0-1 1v5h-5a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h5v5a1 1 0 0 0 1 1h2Z"></path>
      </g>
    </svg>
  )
}
