import { Navigate, useRoutes } from 'react-router-dom'
import LayoutIndex from '@/components/Layout'
import Clone from '@/views/clone'
import CreateVideo from '@/views/create-video'
import Home from '@/views/home/index'
import Login from '@/views/login'
import Setting from '@/views/setting'
import Video from '@/views/video/index'
import ResetPassword from '@/views/setting/resetPassword'

export const rootRouter = [
  {
    path: '/login',
    element: <Login />,
    meta: {
      requiresAuth: false,
      title: '登录页'
    }
  },
  {
    path: '/create-video',
    element: <CreateVideo />,
    meta: {
      requiresAuth: true,
      title: '创建视频'
    }
  },
  {
    path: '/create-video/:avatarId',
    element: <CreateVideo />,
    meta: {
      requiresAuth: true,
      title: '创建视频'
    }
  },
  {
    element: <LayoutIndex />,
    children: [
      {
        path: '/home',
        element: <Home />,
        meta: {
          requiresAuth: true,
          title: '首页'
        }
      },
      {
        path: '/clone',
        element: <Clone />,
        meta: {
          requiresAuth: true,
          title: '声音克隆'
        }
      },
      {
        path: '/video',
        element: <Video />,
        meta: {
          requiresAuth: true,
          title: '视频'
        }
      },
      {
        path: '/setting',
        element: <Setting />,
        meta: {
          requiresAuth: true,
          title: '个人中心',
          headerShow: true
        }
      },
      {
        path: '/reset/password',
        element: <ResetPassword />,
        meta: {
          requiresAuth: true,
          title: '修改密码',
          headerShow: true
        }
      }
    ]
  },
  {
    path: '*',
    element: <Navigate to="/home" />
  }
]

const Router = () => {
  const routes = useRoutes(rootRouter)
  return routes
}

export default Router
