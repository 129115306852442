export const Ellipsis = (props: any) => {
  return (
    <svg
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 48 48"
      {...props}
    >
      <g>
        <path d="M8.5 28a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9ZM28.5 23.5a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0ZM44 23.5a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Z"></path>
      </g>
    </svg>
  )
}
