import './style.scss'
import { Dropdown, Input, message, Modal } from 'antd'
import { Buffer } from 'buffer'
import { FC, memo, useEffect, useRef, useState } from 'react'
import wavConverter from 'wav-converter'
import * as Api from '@/api/account'
import * as homeApi from '@/api/home'
import AudioTag from '@/assets/audio-tag.webp'
import ModelBase from '@/assets/model-base.webp'
import ModelHigh from '@/assets/model-high.webp'
import { Ellipsis, Play } from '@/assets/svg'
import { Arrow } from '@/assets/svg/arrow'
import PlanModal from '@/components/PlanModal'
import { IUserPackage } from '@/global-states'
import { eventTracking } from '@/libs/util'
import { DeleteOutlined, EditOutlined, LoadingOutlined } from '@ant-design/icons'
import CloneDrawer from './components/clone-drawer'
import CloneHighDrawer from './components/clone-high-drawer'

const Clone: FC = () => {
  const [globalTts, setGlobalTts] = useState<any[]>([])
  const [selfTts, setSelfTts] = useState<any[]>([])
  const [open, setOpen] = useState(false)
  const [cloneHighOpen, setCloneHighOpen] = useState(false)
  const audioRef = useRef<any>()
  const [previewId, setPreviewId] = useState<any>(undefined)
  const prviewRef = useRef<any>()
  const [renameModalOpen, setRenameModalOpen] = useState(false)
  const [editVoice, setEditVoice] = useState<any>()
  const timeRef = useRef<any>()
  const [modelModal, setModelModal] = useState(false)
  const [planModalOpen, setPlanModalOpen] = useState(false)
  const [userPackage, setUserPackage] = useState<IUserPackage>()

  useEffect(() => {
    eventTracking('VoiceClonePage')
    ;(window as any).Buffer = Buffer
    getGlobalTts()
    getSelfTts()
    getUserPackage()
    timeRef.current = setInterval(() => {
      getSelfTts()
    }, 5000)
    return () => {
      prviewRef.current = undefined
      closeAudio()
      if (timeRef.current) {
        clearInterval(timeRef.current)
        timeRef.current = null
      }
    }
  }, [])

  useEffect(() => {
    prviewRef.current = previewId
  }, [previewId])

  const getSelfTts = async () => {
    const res = await homeApi.getSelfTts()
    setSelfTts(res.list || [])
  }

  const getGlobalTts = async () => {
    const res = await homeApi.getGlobalTts()
    setGlobalTts(res.list || [])
  }

  const getUserPackage = async () => {
    const res: IUserPackage = await Api.getUserPackage()
    setUserPackage(res || {})
  }

  const cloneAudio = () => {
    eventTracking('InstantCloneBasicClick')
    setModelModal(false)
    setOpen(true)
  }

  const upgradePlan = () => {
    Modal.confirm({
      title: '当前会员等级不够，无法使用',
      content: <div>声音克隆-高保真是尊享版级以上会员专属功能，请您确认当前会员等级收否匹配</div>,
      okText: '升级会员',
      cancelText: '取消',
      onOk: () => {
        setPlanModalOpen(true)
      }
    })
  }

  const cloneHigAudio = async () => {
    eventTracking('InstantClonePremiumClick')
    setModelModal(false)
    if (userPackage && (userPackage.current_membership_level || 0) < 20) {
      return upgradePlan()
    }

    const res = await homeApi.getCloneableVoices()

    if (res?.list?.[0]?.last_demo_source_audio) {
      Modal.warning({
        title: '温馨提示',
        content: '您有一个克隆中的声音尚未完成克隆，点击前往克隆页面，去完成克隆。',
        okText: '去完成克隆',
        onOk: () => {
          setCloneHighOpen(true)
        }
      })
    } else {
      setCloneHighOpen(true)
    }
  }

  const onOk = () => {
    getSelfTts()
  }

  const delVoice = async (id: number) => {
    await homeApi.deleteVoice(id)
    message.success('删除成功')
    getSelfTts()
  }

  const previewTts = async (tts: any, text: string) => {
    closeAudio()
    setPreviewId(tts.id)

    try {
      const res = await homeApi.previewTts(tts.id, { text, voice_parameters: tts.voice_parameters })

      if (prviewRef.current === tts.id) {
        const audio = new Audio()
        audio.src = `data:audio/wav;base64,${wavConverter
          .encodeWav(new Buffer(res.audio_base64, 'base64'), {
            numChannels: 1,
            sampleRate: 16000,
            byteRate: 32_000
          })
          .toString('base64')}`
        setPreviewId(undefined)
        audio.play()
        audioRef.current = audio
      }
    } catch {
      setPreviewId(undefined)
    }
  }

  const closeAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause?.()
      audioRef.current.src = ''
    }
  }

  const renameVoice = (v: any) => {
    setEditVoice(v)
    setRenameModalOpen(true)
  }

  const sureRenameVoice = async () => {
    await homeApi.updateVoice(editVoice.id, {
      voice_name: editVoice.display_name
    })
    message.success('修改成功')
    setRenameModalOpen(false)
    getSelfTts()
  }

  return (
    <div className="page-common page-clone">
      <div className="common-title">
        <div className="text">个人声音克隆{selfTts.length ? `（${selfTts.length}）` : null}</div>
      </div>
      <div className="clone-self">
        <div
          className="box-blank"
          onClick={() => {
            eventTracking('InstantCloneClick')
            setModelModal(true)
          }}
        >
          <div className="left">
            <div className="title">声音克隆</div>
            <div className="desc">
              <div>上传音频，打造专属个人</div>
              <div>声音模型</div>
            </div>
          </div>
          <div className="right">
            <div className="btn">
              快速克隆
              <Arrow />
            </div>
          </div>
        </div>
        {selfTts?.map((t) => (
          <div className={`box ${t.status === 1 ? '' : 'status'}`} key={t.id}>
            <div className="box-audio">
              <div
                className="left"
                onClick={() => {
                  if (userPackage && (userPackage.current_membership_level || 0) < 20 && t.level === 20) {
                    return upgradePlan()
                  }
                  previewTts(t, '现在的一切都是为将来的梦想编织翅膀，让梦想在现实中展翅高飞。')
                }}
              >
                {previewId === t.id ? <LoadingOutlined /> : <Play />}
              </div>
              <div className="center">
                <div className="name ellipsis">{t.display_name}</div>
              </div>
              {t.level === 20 && <img className="tag" src={AudioTag} />}

              {(t.status === 1 || t.status === 3) && (
                <div className="right">
                  <Dropdown
                    menu={{
                      items: [
                        {
                          key: '1',
                          label: (
                            <div className="dropdown-list" onClick={renameVoice.bind(this, t)}>
                              <EditOutlined />
                              重命名
                            </div>
                          )
                        },
                        {
                          key: '2',
                          label: (
                            <div className="dropdown-list" onClick={delVoice.bind(this, t.id)}>
                              <DeleteOutlined />
                              删除
                            </div>
                          )
                        }
                      ].filter((m: any) => {
                        if (t.level === 20 && m.key === '2') {
                          return false
                        }
                        return true
                      })
                    }}
                    placement="bottom"
                  >
                    <Ellipsis />
                  </Dropdown>
                </div>
              )}
              {t.status === 2 && (
                <div className="cloning">
                  <label>克隆中...</label>
                </div>
              )}

              {t.status === 3 && (
                <div className="error">
                  <label>克隆失败</label>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="common-title">
        <div className="text">声音库{globalTts.length ? `（${globalTts.length}）` : null}</div>
      </div>
      <div className="clone-common">
        {globalTts?.map((t) => (
          <div className="box" key={t.id}>
            <div className="box-audio">
              <div
                className="left"
                onClick={() => previewTts(t, '现在的一切都是为将来的梦想编织翅膀，让梦想在现实中展翅高飞。')}
              >
                {previewId === t.id ? <LoadingOutlined /> : <Play />}
              </div>
              <div className="center">
                <div className="name ellipsis">{t.display_name}</div>
              </div>

              {t.level === 20 && <img className="tag" src={AudioTag} />}
            </div>
          </div>
        ))}
      </div>
      <CloneDrawer open={open} onCancel={() => setOpen(false)} onOk={onOk} />
      <CloneHighDrawer open={cloneHighOpen} onCancel={() => setCloneHighOpen(false)} onOk={onOk} />

      <Modal open={modelModal} title="模式选择" footer={null} onCancel={() => setModelModal(false)}>
        <div className="model-modal">
          <div className="model-item" onClick={cloneAudio}>
            <div className="left">
              <img src={ModelBase} />
            </div>
            <div className="right">
              <div className="title">声音克隆-基础版</div>
              <div className="desc">上传5~30S音频，快速克隆个人声音</div>
            </div>
          </div>
          <div className="model-item" onClick={cloneHigAudio}>
            <div className="left">
              <img src={ModelHigh} />
            </div>
            <div className="right">
              <div className="title">声音克隆-高保真</div>
              <div className="desc">上传5~30S音频，高度还原真人音色特点、说话风格、 口音和声学环境</div>
            </div>
            <div className="tag">尊享专属</div>
          </div>
        </div>
      </Modal>

      <Modal
        open={renameModalOpen}
        title="重命名"
        okText="确定"
        cancelText="取消"
        onCancel={() => setRenameModalOpen(false)}
        onOk={sureRenameVoice}
      >
        <div style={{ padding: 20 }}>
          <Input
            style={{ height: 40 }}
            value={editVoice?.display_name}
            onChange={(e) =>
              setEditVoice({
                ...editVoice,
                display_name: e.target.value
              })
            }
          />
        </div>
      </Modal>

      <PlanModal
        open={planModalOpen}
        onCancel={() => setPlanModalOpen(false)}
        onSuccess={() =>
          setTimeout(() => {
            getUserPackage()
          }, 300)
        }
      />
    </div>
  )
}

export default memo(Clone)
