import './style.scss'
import { Input, message, Modal, Slider, Tooltip } from 'antd'
import dayjs from 'dayjs'
import { FC, memo, useEffect, useMemo, useState } from 'react'
import * as Api from '@/api/account'
import Code from '@/assets/code.webp'
import { Check } from '@/assets/svg/check'
import TabMonth from '@/assets/tab-month.png'
import TabYear from '@/assets/tab-year.png'
import Person from '@/assets/user.png'
import { IProduct, IUserPackage, UserStore } from '@/global-states'
import { eventTracking, findClosest } from '@/libs/util'
import { InfoCircleOutlined } from '@ant-design/icons'
import PayModal from '../PayModal'

export interface IProductList {
  base: IProduct[]
  high: IProduct[]
}

interface IProps {
  open: boolean
  onCancel?: () => void
  onSuccess?: () => void
}

const PlanModal: FC<IProps> = (props) => {
  const { open, onCancel, onSuccess } = props
  const { phone } = UserStore
  const [userPackage, setUserPackage] = useState<IUserPackage>(undefined as any)
  const [type, setType] = useState<'month' | 'year'>('month')
  const [payModalOpen, setPayModalOpen] = useState(false)
  const [baseRate, setBaseRate] = useState(0)
  const [highRate, setHighRate] = useState(0)
  const [baseProduct, setBaseProduct] = useState<IProduct>()
  const [highProduct, setHighProduct] = useState<IProduct>()
  const [productList, setProductList] = useState<IProductList>({
    base: [],
    high: []
  } as IProductList)
  const [allProductList, setAllProductList] = useState<IProduct[]>([])
  const [payProduct, setPayProduct] = useState<IProduct>()
  const [convertModalOpen, setConvertModalOpen] = useState(false)
  const [code, setCode] = useState('')

  const vip = useMemo(() => {
    return !!userPackage?.membership_expire_time
  }, [userPackage])

  const vipExpired = useMemo(() => {
    return !!userPackage?.membership_expired
  }, [userPackage])

  const slider = useMemo(() => {
    if (productList) {
      return {
        base: productList.base.reduce((pre, next, index) => {
          const number = Math.floor(index * (100 / (productList.base.length - 1)))
          pre[number] = `${(next.unit_credits * next.units) / 10000}w`
          return pre
        }, {} as any),
        high: productList.high.reduce((pre, next, index) => {
          const number = Math.floor(index * (100 / (productList.base.length - 1)))
          pre[number] = `${(next.unit_credits * next.units) / 10000}w`
          return pre
        }, {} as any)
      }
    }
  }, [productList])

  useEffect(() => {
    const index = productList.base.findIndex((p) => p.id === baseProduct?.id)
    setBaseRate(Math.floor(index * (100 / (productList.base.length - 1))))
  }, [baseProduct])

  useEffect(() => {
    const index = productList.high.findIndex((p) => p.id === highProduct?.id)
    setHighRate(Math.floor(index * (100 / (productList.high.length - 1))))
  }, [highProduct])

  useEffect(() => {
    if (open) {
      setType('month')
      getUserPackage()
      getProductList()
    }
  }, [open])

  const getUserPackage = async () => {
    const res: IUserPackage = await Api.getUserPackage()
    setUserPackage(res || {})
  }

  const getProductList = async () => {
    const { list }: { list: IProduct[] } = await Api.getProductList()
    const products = {
      base: list.filter((l) => l.level === 10 && l.units === 1),
      high: list.filter((l) => l.level === 20 && l.units === 1)
    }

    setProductList(products)
    setAllProductList(list)
    setBaseProduct(products.base?.[0])
    setHighProduct(products.high?.[0])
  }

  const onTabChange = (type: 'month' | 'year', e: any) => {
    const dw = document.documentElement.clientWidth
    let products
    if (e.clientX >= dw / 2 && type === 'year') {
      products = {
        base: allProductList.filter((l) => l.level === 10 && l.units === 12),
        high: allProductList.filter((l) => l.level === 20 && l.units === 12)
      }
    }

    if (e.clientX <= dw / 2 && type === 'month') {
      products = {
        base: allProductList.filter((l) => l.level === 10 && l.units === 1),
        high: allProductList.filter((l) => l.level === 20 && l.units === 1)
      }
    }
    if (products) {
      setProductList(products)
      setBaseProduct(products.base?.[0])
      setHighProduct(products.high?.[0])
    }
    setType(type)
  }

  const onBaseRateChange = (v: number) => {
    const index = findClosest(
      Object.keys(slider?.base || {}).map((o) => +o),
      v
    )
    setBaseProduct(productList.base?.[index])
  }

  const onHighRateChange = (v: number) => {
    const index = findClosest(
      Object.keys(slider?.base || {}).map((o) => +o),
      v
    )
    setHighProduct(productList.high?.[index])
  }

  const upgradeTip = (e: any) => {
    e.stopPropagation()
    Modal.warn({
      title: '升级规则',
      content: (
        <div className="declare-list" style={{ marginLeft: -20 }}>
          <ul style={{ paddingLeft: 10 }}>
            <li style={{ padding: '8px 0' }}>升级后功能权益立即生效, 升级后套餐的积分即刻到账;</li>
            <li style={{ padding: '8px 0' }}>旧套餐不做退补差价，已经给到的积分不会失效;</li>
            <li style={{ padding: '8px 0' }}>
              旧套餐剩余权益(会员时间等)自动延后，在升级套餐结束后继续生效。例如: 用户当前是基础单月会员(2030-02-01 ~
              2030-03-01)，该会员将在2030-03-01到期，如果用户在2030-02-15当天选择升级尊享单月会员，尊享单月会员立即生效(2030-02-15
              ~ 2030-03-15)，尊享单月会员在2030-03-15到期后，旧的基础单月会员还有15天权益，将于(2030-03-15 ~
              2030-04-01)继续生效。
            </li>
          </ul>
        </div>
      ),
      okText: '知道了'
    })
  }

  const useCode = async () => {
    await Api.useCode({ redeem_code: code })
    setConvertModalOpen(false)
    message.success('兑换成功')
    onSuccess?.()
  }

  const onSub = (product?: IProduct) => {
    onCancel?.()
    setPayProduct(product)
    setPayModalOpen(true)
  }

  return null

  return (
    <>
      <Modal className="plan-modal" footer={null} title={null} onCancel={() => onCancel?.()} open={open}>
        <div className="plan-content">
          <div className="plan-content-header">
            <img src={Person} />
            <div className="user-info">
              <div className="phone">
                <label>{phone}</label>
                {vip && !vipExpired && (
                  <label className="tag">{userPackage?.current_membership_package_title}会员</label>
                )}
              </div>
              {vip && userPackage ? (
                <>
                  {vipExpired ? (
                    <label className="text red">
                      套餐已于{dayjs(userPackage.membership_expire_time * 1000).format('YYYY-MM-DD HH:mm')}过期
                    </label>
                  ) : (
                    <label className="text">
                      到期时间：{dayjs(userPackage.membership_expire_time * 1000).format('YYYY-MM-DD HH:mm')}
                    </label>
                  )}
                </>
              ) : (
                <label className="text">未开通会员</label>
              )}
            </div>
          </div>
          <div className="plan-content-main" style={{ opacity: productList ? 1 : 0 }}>
            <div className="tabs">
              {type === 'month' ? (
                <img src={TabMonth} onClick={onTabChange.bind(this, 'year')} />
              ) : (
                <img src={TabYear} onClick={onTabChange.bind(this, 'month')} />
              )}
            </div>

            <div className="plan-list">
              <div className="list base">
                <div className="top">
                  <div className="title">基础版</div>
                  <div>解锁更多会员权益</div>
                </div>
                <div className="main">
                  <div className="price-item">
                    <div className="desc">
                      <label className="unit">¥</label>
                      <label className="price">
                        {((baseProduct?.discounted_unit_price || 0) * (baseProduct?.units || 0)) / 100}
                      </label>
                      <label className="time">/{type === 'month' ? '月' : '年'}</label>
                      {baseProduct?.discounted_unit_price !== baseProduct?.original_unit_price && (
                        <label className="orgin-price">
                          （原价{((baseProduct?.original_unit_price || 0) * (baseProduct?.units || 0)) / 100}/
                          {type === 'month' ? '月' : '年'}）
                        </label>
                      )}
                    </div>
                    <div className="slider">
                      <Slider
                        marks={slider?.base}
                        value={baseRate}
                        tooltip={{ open: false }}
                        onChange={onBaseRateChange}
                      />
                    </div>
                  </div>
                  <div className="desc-list">
                    <div className="item">
                      <div className="left">{type === 'year' ? '总' : ''}积分数量</div>
                      <div className="right">{(baseProduct?.unit_credits || 0) * (baseProduct?.units || 0)}</div>
                    </div>
                    <div className="item">
                      <div className="left">形象复刻（限时免费）</div>
                      <div className="right">每月100次</div>
                    </div>
                    <div className="item">
                      <div className="left">声音克隆-基础版（限时免费）</div>
                      <div className="right">
                        <Check />
                      </div>
                    </div>
                    <div className="item">
                      <div className="left">数字人创作</div>
                      <div className="right">
                        <Check />
                      </div>
                    </div>
                    <div className="item">
                      <div className="left">会员去水印</div>
                      <div className="right">
                        <Check />
                      </div>
                    </div>
                  </div>
                  {baseProduct?.status === 1 && (
                    <div
                      className="btn blue"
                      onClick={() => {
                        eventTracking('BuyNow')
                        onSub(baseProduct)
                      }}
                    >
                      立即购买
                    </div>
                  )}
                  {baseProduct?.status === 2 && (
                    <div
                      className="btn blue"
                      onClick={() => {
                        eventTracking('UpgradeNow')
                        onSub(baseProduct)
                      }}
                    >
                      立即升级
                    </div>
                  )}
                  {baseProduct?.status === 3 && (
                    <div
                      className="btn blue"
                      onClick={() => {
                        eventTracking('RenewNow')
                        onSub(baseProduct)
                      }}
                    >
                      立即续费
                    </div>
                  )}
                  {baseProduct?.status === 4 && <div className="btn gray">暂不支持会员降级</div>}
                </div>
              </div>
              <div className="list high">
                <div className="top">
                  <div className="title">尊享版</div>
                  <div>解锁更多会员权益</div>
                </div>
                <div className="main">
                  <div className="price-item">
                    <div className="desc">
                      <label className="unit">¥</label>
                      <label className="price">
                        {((highProduct?.discounted_unit_price || 0) * (highProduct?.units || 0)) / 100}
                      </label>
                      <label className="time">/{type === 'month' ? '月' : '年'}</label>
                      {highProduct?.discounted_unit_price !== highProduct?.original_unit_price && (
                        <label className="orgin-price">
                          （原价{((highProduct?.original_unit_price || 0) * (highProduct?.units || 0)) / 100}/
                          {type === 'month' ? '月' : '年'}）
                        </label>
                      )}
                    </div>
                    <div className="slider">
                      <Slider
                        marks={slider?.high}
                        value={highRate}
                        tooltip={{ open: false }}
                        onChange={onHighRateChange}
                      />
                    </div>
                  </div>
                  <div className="desc-list">
                    <div className="item">
                      <div className="left">{type === 'year' ? '总' : ''}积分数量</div>
                      <div className="right">{(highProduct?.unit_credits || 0) * (highProduct?.units || 0)}</div>
                    </div>
                    <div className="item">
                      <div className="left">包含基础版所有权益</div>
                      <div className="right">
                        <Check />
                      </div>
                    </div>
                    <div className="item">
                      <div className="left">形象复刻（限时免费）</div>
                      <div className="right">不限次数</div>
                    </div>

                    <div className="item">
                      <div className="left">
                        声音克隆-高保真:
                        <a
                          target="_blank"
                          href="https://lingverse.feishu.cn/docx/U6qiddhObonp56x3w1ucAlJ6nqb"
                          rel="noreferrer"
                        >
                          （说明）
                        </a>
                      </div>
                      <div className="right">
                        <Check />
                      </div>
                    </div>
                    <div className="item">
                      <div className="left">优先队列</div>
                      <div className="right">
                        <Check />
                      </div>
                    </div>
                  </div>
                  {highProduct?.status === 1 && (
                    <div
                      className="btn purple"
                      onClick={() => {
                        eventTracking('BuyNow')
                        onSub(highProduct)
                      }}
                    >
                      立即购买
                      <span className="tag">限量发售</span>
                    </div>
                  )}
                  {highProduct?.status === 2 && (
                    <div
                      className="btn purple"
                      onClick={() => {
                        eventTracking('UpgradeNow')
                        onSub(highProduct)
                      }}
                    >
                      立即升级
                      <span className="tag">限量发售</span>
                      <div className="tips" onClick={upgradeTip}>
                        升级规则 <InfoCircleOutlined />
                      </div>
                    </div>
                  )}
                  {highProduct?.status === 3 && (
                    <div
                      className="btn purple"
                      onClick={() => {
                        eventTracking('RenewNow')
                        onSub(highProduct)
                      }}
                    >
                      立即续费
                      <span className="tag">限量发售</span>
                    </div>
                  )}
                  {highProduct?.status === 4 && (
                    <div className="btn gray">
                      暂不支持会员降级<span className="tag">限量发售</span>
                    </div>
                  )}
                  {highProduct?.status === 5 && (
                    <div className="btn gray">
                      已售罄<span className="tag">敬请期待</span>
                    </div>
                  )}
                </div>
              </div>
              <div className="list company">
                <div className="top">
                  <div className="title">企业版</div>
                  <div>根据企业进行私有化定制</div>
                </div>
                <div className="main">
                  <div className="contact">
                    <div className="left">
                      <img src={Code} />
                    </div>
                    <div className="right">
                      <div className="text">联系我们</div>
                    </div>
                  </div>
                  <div className="desc-list">
                    <div className="item">
                      <div className="left">更大的积分额度</div>
                      <div className="right">
                        <Check />
                      </div>
                    </div>

                    <div className="item">
                      <div className="left">包含尊享版所有权益</div>
                      <div className="right">
                        <Check />
                      </div>
                    </div>
                    <div className="item">
                      <div className="left">API接入</div>
                      <div className="right">
                        <Check />
                      </div>
                    </div>
                    <div className="item">
                      <div className="left">最高优先队列</div>
                      <div className="right">
                        <Check />
                      </div>
                    </div>

                    <div className="item">
                      <div className="left">在线服务支持</div>
                      <div className="right">
                        <Check />
                      </div>
                    </div>
                  </div>

                  <Tooltip title="扫描上方二维码联系我们" trigger={['click']}>
                    <div className="btn black">联系我们</div>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <PayModal
        open={payModalOpen}
        onCancel={() => setPayModalOpen(false)}
        product={payProduct}
        onSuccess={() => onSuccess?.()}
      />

      <Modal
        okText="立即激活"
        cancelText="取消"
        title="请输入激活码"
        onCancel={() => setConvertModalOpen(false)}
        open={convertModalOpen}
        okButtonProps={{ disabled: !code }}
        onOk={useCode}
      >
        <div className="convert-content">
          <Input value={code} onChange={(e) => setCode(e.target.value)} />
        </div>
      </Modal>
    </>
  )
}

export default memo(PlanModal)
