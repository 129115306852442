import { authApi, cmsApi, payApi } from '@/api'
import { UserStore } from '@/global-states'

export const getUserInfo = async () => {
  if (UserStore.getToken()) {
    const res: any = await authApi.get('api/v1/metax/users/info/get')
    UserStore.setUserInfo(res?.userinfo || {})
  }
}

export const getUserPackage = async () => {
  return cmsApi.get(`my_credits_info`)
}

export const getProductList = async () => {
  return cmsApi.get(`membership_packages`)
}

export const getProfile = async () => {
  return cmsApi.get(`affiliate_marketing/profile`)
}

export const getPermanentProductList = async () => {
  return cmsApi.get(`permanent_credits_packages`)
}

export const orderCreate = async (params: any) => {
  return cmsApi.post(`membership_orders`, params)
}

export const permanentOrderCreate = async (params: any) => {
  return cmsApi.post(`permanent_credits_orders`, params)
}

export const paymentInit = async (params: any) => {
  return payApi.post(`payment/init`, params)
}

export const paymentCheck = async (params: any) => {
  return payApi.post(`payment/check`, params)
}

export const useCode = (params: any) => {
  return cmsApi.post(`redeem_codes/apply`, params)
}

export const getCreditsLogs = async () => {
  return cmsApi.get(`affiliate_marketing/credits_logs`)
}

export const getAllCreditLogs = async () => {
  return cmsApi.get(`credits_logs`)
}

export const getPreflight = async () => {
  return cmsApi.get(`tts_demos/preflight`)
}

export const clonePreflight = async (data: any) => {
  return cmsApi.get(`tts_voice_clone_preflight`, data)
}

export const getSystemConfig = async (data: any) => {
  return cmsApi.get(`sys_config`, data)
}

export const resetPassword = (params: any) => {
  return authApi.put(`api/v1/metax/users/password/edit`, params)
}
