export const User = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      version="1.1"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      {...props}
    >
      <defs>
        <clipPath id="master_svg0_411_24109">
          <rect x="0" y="0" width="14" height="14" rx="0" />
        </clipPath>
      </defs>
      <g clipPath="url(#master_svg0_411_24109)">
        <g>
          <path
            d="M6.99996,8.16666C8.71958,8.16666,10.3068,6.66438,10.4847,4.46724C10.6707,2.1683,9.14397,0,6.99996,0C4.85603,0,3.32918,2.1683,3.51537,4.46724C3.6932,6.66438,5.28042,8.16666,6.99996,8.16666ZM6.96881,9.35256C3.12003,9.35256,0,10.8831,0,12.7711C0,13.4167,0.59351,13.6655,2.339,13.8542C3.68836,14,5.29936,14,7,14C8.74199,14,10.4696,14,11.685,13.8542C13.4043,13.6479,14,13.4167,14,12.7711C14,10.8831,10.8175,9.35256,6.96881,9.35256Z"
            fill="#272636"
            fillOpacity="1"
          />
        </g>
      </g>
    </svg>
  )
}
