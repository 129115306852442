import './style.scss'
import { Dropdown, MenuProps } from 'antd'
import { FC, memo } from 'react'
import { useNavigate } from 'react-router-dom'
import { Agreement } from '@/assets/svg/agreement'
import { Arrow } from '@/assets/svg/arrow'
import { Loginout } from '@/assets/svg/loginout'
import { Privacy } from '@/assets/svg/privacy'
import { Password } from '@/assets/svg/password'
import { User } from '@/assets/svg/user'
import { UserStore } from '@/global-states'
import { eventTracking } from '@/libs/util'
import { ArrowLeftOutlined } from '@ant-design/icons'

interface IProps {
  title?: string
}

const LayoutHeader: FC<IProps> = (props) => {
  const { title } = props
  const navigate = useNavigate()
  const { head_logo, eula_url, privacy_agreement_url } = UserStore.config

  const loginOut = () => {
    UserStore.loginOut()
  }

  const items: MenuProps['items'] = [
    {
      key: '0',
      label: (
        <a href="javascript:void(0)" onClick={() => navigate('/setting')}>
          <User />
          个人中心
        </a>
      )
    },
    {
      key: '1',
      label: (
        <a target="_blank" rel="noopener noreferrer" className="header-menu-item" href={privacy_agreement_url}>
          <Privacy />
          隐私协议
        </a>
      )
    },
    {
      key: '3',
      label: (
        <a target="_blank" rel="noopener noreferrer" href={eula_url}>
          <Agreement />
          用户协议
        </a>
      )
    },
    {
      key: '5',
      label: (
        <a href="javascript:void(0)" onClick={() => navigate('/reset/password')}>
          <Password />
          修改密码
        </a>
      )
    },
    {
      key: '4',
      label: (
        <a target="_blank" rel="noopener noreferrer" onClick={loginOut}>
          <Loginout />
          退出登录
        </a>
      )
    }
  ]

  return (
    <div className="layout-header">
      <div className="title">
        {title ? (
          <>
            <span className="icon" onClick={() => navigate(-1)}>
              <ArrowLeftOutlined />
            </span>
            <label>{title}</label>
          </>
        ) : (
          <img src={head_logo} onClick={() => navigate('/')} />
        )}
      </div>
      <div className="right">
        <div
          className="btn"
          onClick={() => {
            eventTracking('CreateDisplayClick')
            navigate('/create-video')
          }}
        >
          创建视频 <Arrow />
        </div>

        <Dropdown
          className="header-menu"
          menu={{ items }}
          placement="bottomRight"
          arrow
          overlayClassName={'header-menu-item'}
        >
          <div className="user">AI</div>
        </Dropdown>
      </div>
    </div>
  )
}

export default memo(LayoutHeader)
