import './style.scss'
import { Button, Checkbox, Input } from 'antd'
import { FC, memo, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as Api from '@/api/login'
import { UserStore } from '@/global-states'
import { LoginStore } from '../../states'

const MobileLogin: FC = () => {
  const { phone, agreementChecked } = LoginStore
  const [mobileError, setMobileError] = useState(false)
  const [captcha, setCaptcha] = useState('')
  const [captchaError, setCaptchaError] = useState(false)
  const [captchaButtonDisableMessage, setCaptchaButtonDisableMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const { eula_url, privacy_agreement_url } = UserStore.config
  const navigate = useNavigate()

  useEffect(() => {
    setMobileError(phone.length > 11)
  }, [phone])

  useEffect(() => {
    setCaptchaError(captcha.length > 6)
  }, [captcha])

  const onMobileChange = (e: any) => {
    LoginStore.phone = e.target.value.replaceAll(/\D/g, '')
  }

  const oncCaptchaChange = (e: any) => {
    setCaptcha(e.target.value.replaceAll(/\D/g, ''))
  }

  const onAgreeChange = (e: any) => {
    LoginStore.agreementChecked = e.target.checked
  }

  const switchLoginType = () => {
    LoginStore.loginType = 'password'
  }

  const onRememberAccountChange = (e: any) => {
    LoginStore.rememberAccount = e.target.checked
  }

  // 获取验证码
  const getCaptcha = () => {
    Api.sendSms({ phone, code_type: 'REORLOGINCODE' })
    let index = 60
    setCaptchaButtonDisableMessage(`已发送 ${index}S`)
    const timer = setInterval(() => {
      index--
      setCaptchaButtonDisableMessage(`已发送 ${index}S`)
      if (index < 1) {
        clearInterval(timer)
        setCaptchaButtonDisableMessage('')
      }
    }, 1000)
  }

  const onPressEnter = () => {
    if (phone.length === 11 && captcha.length === 6 && agreementChecked) {
      doLogin()
    }
  }

  // 登陆
  const doLogin = async () => {
    setLoading(true)
    const invite_code = sessionStorage.getItem('code')
    try {
      const user = await Api.login({
        phone,
        code: captcha,
        type: 'CODE',
        invite_code
      })
      if (!user?.token) {
        throw new Error('登录失败')
      }
      UserStore.setUserToken(user)
      const redirectUrl = sessionStorage.getItem('redirectUrl')
      if (invite_code && redirectUrl) {
        sessionStorage.removeItem('redirectUrl')
        setTimeout(() => {
          navigate(`${redirectUrl}?code=${invite_code}`)
        })
      } else {
        navigate('/home')
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="mobile-login">
      <div className="login-phone">
        <Input
          className="input-with-hint"
          value={phone}
          maxLength={20}
          onChange={onMobileChange}
          placeholder="请输入 11 位手机号"
          status={mobileError ? 'error' : undefined}
        />
      </div>
      <div className="login-capt">
        <Input
          className="input-with-hint"
          value={captcha}
          maxLength={20}
          onChange={oncCaptchaChange}
          status={captchaError ? 'error' : undefined}
          placeholder="请输入 6 位验证码"
          onPressEnter={onPressEnter}
        />
        <Button
          className="borderless btn-capt"
          onClick={getCaptcha}
          type="primary"
          disabled={!!captchaButtonDisableMessage || phone.length !== 11}
        >
          {captchaButtonDisableMessage || '获取验证码'}
        </Button>
      </div>

      {/* <div className="op">
        <Checkbox checked={rememberAccount} onChange={onRememberAccountChange}>
          <label>记住账号</label>
        </Checkbox>
        <label className="switch" onClick={switchLoginType}>
          密码登录
        </label>
      </div> */}

      <Button
        className="mobile-login-button"
        type="primary"
        disabled={phone.length !== 11 || captcha.length !== 6 || !agreementChecked}
        onClick={doLogin}
        loading={loading}
      >
        登录
      </Button>

      <div className="login-agree">
        <Checkbox checked={agreementChecked} onChange={onAgreeChange}>
          <label>我已阅读并同意</label>
          <a href={eula_url} target="_blank" rel="noreferrer">
            《用户协议》
          </a>
          <label>与</label>
          <a href={privacy_agreement_url} target="_blank" rel="noreferrer">
            《隐私条款》
          </a>
        </Checkbox>
      </div>
    </div>
  )
}

export default memo(MobileLogin)
