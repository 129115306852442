export const Privacy = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      version="1.1"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...props}
    >
      <defs>
        <clipPath id="master_svg0_411_24133">
          <rect x="0" y="0" width="16" height="16" rx="0" />
        </clipPath>
      </defs>
      <g clipPath="url(#master_svg0_411_24133)">
        <g>
          <path
            d="M2.671812,1.96437C3.2315300000000002,2.1439,3.95747,2.12036,4.85187,1.8762C5.781829999999999,1.62234,6.72494,1.175545,7.68128,0.5330764C7.86739,0.4080549,8.109960000000001,0.4054887,8.298670000000001,0.5265452C9.337579999999999,1.192998,10.28842,1.64195,11.14789,1.87612C11.9767,2.1019699999999997,12.7109,2.12581,13.357,1.95597C13.7137,1.86225,14.0625,2.13126,14.0625,2.5L14.0625,9.3064C14.0625,12.1689,12.0603,14.6414,9.2603,15.2365C8.90686,15.3116,8.546199999999999,15.4105,8.1783,15.5335C8.06304,15.572,7.93841,15.5722,7.82305,15.534C7.455,15.412,7.09458,15.3136,6.74177,15.2388C3.9408,14.6445,1.9375,12.1716,1.9375,9.30828L1.9375,2.5C1.9375,2.11875,2.308766,1.84794,2.671812,1.96437ZM7.00609,9.88305L5.26891,7.96562C5.08441,7.77437,4.81531,7.81039,4.63261,8.00351L4.20589,8.45464C4.16053,8.50259,4.16053,8.57983,4.20589,8.62778L6.26878,10.8853L6.26878,10.8853L6.6728,11.3108L6.67609,11.3142C6.86061,11.5055,7.15828,11.5039,7.34098,11.3108L11.80884,6.42067C11.8542,6.37272,11.8542,6.29548,11.80884,6.24753L11.35444,5.77401C11.16992,5.58276,10.89664,5.60328,10.71397,5.7964L7.00609,9.88305Z"
            fill="#2C2C2C"
            fillOpacity="1"
          />
        </g>
      </g>
    </svg>
  )
}
