import './style.scss'
import { Dropdown, Input, message } from 'antd'
import { FC, memo, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as Api from '@/api/home'
import { CreateVideo, Ellipsis, Play } from '@/assets/svg'
import { Arrow } from '@/assets/svg/arrow'
import VideoModal from '@/components/VideoModal'
import { eventTracking, urlSource } from '@/libs/util'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import CreateAvatar from '../create-avatar'

const Index: FC = () => {
  const navigate = useNavigate()
  const [editId, setEditId] = useState<any>()
  const [preview, setPreview] = useState<any>()
  const [openId, setOpenId] = useState<any>()
  const [data, setData] = useState<any[]>([])
  const [globalData, setGlobalData] = useState<any[]>([])
  const [createModalOpen, setCreateModalOpen] = useState(false)

  useEffect(() => {
    eventTracking('InstantAvatarPage')
    getData()
    getGlobalData()
  }, [])

  const getData = async () => {
    const res = await Api.getDigitalHumans()
    setData(res.list || [])
  }

  const getGlobalData = async () => {
    const res = await Api.getGlobalDigitalHumans()
    setGlobalData(res.list || [])
  }

  const previewVideo = (d: any) => {
    setPreview({
      url: d.video_url,
      title: d.title,
      id: d.id
    })
  }

  const onValueChange = (e: any) => {
    setData(
      data.map((d) => {
        return d.id === editId
          ? {
              ...d,
              title: e.target.value
            }
          : d
      })
    )
  }

  const onGlobalValueChange = (e: any) => {
    setGlobalData(
      globalData.map((d) => {
        return d.id === editId
          ? {
              ...d,
              title: e.target.value
            }
          : d
      })
    )
  }

  const saveChange = async (d: any) => {
    setEditId('')
    if (d.title.trim()) {
      Api.updateDigitalHumans(d.id, {
        title: d.title
      })
    } else {
      getData()
    }
  }

  const renameVideo = (id: any, e: any) => {
    e.stopPropagation()
    setOpenId(undefined)
    setEditId(id)
  }

  const deleteVideo = async (id: any, e: any) => {
    e.stopPropagation()
    setOpenId(undefined)
    await Api.deleteDigitalHumans(id)
    getData()
    message.success('删除成功')
  }

  const toCreateAvatar = () => {
    eventTracking('InstantCloneClick')
    setCreateModalOpen(true)
  }

  const toCreateVideo = (id: number) => {
    eventTracking('CreateAvatarClick', {
      avatarId: id
    })
    navigate(`/create-video/${id}`)
  }

  const onCreateSuccess = () => {
    getData()
  }

  return (
    <div className="page-common page-home">
      <div className="common-title">
        <div className="text">我的数字分身{data.length > 0 ? `（${data.length}）` : null}</div>
      </div>
      <div className="page-container">
        <div className="list-box">
          <div className="box-main empty" onClick={toCreateAvatar}>
            <div className="center">
              <div className="t">数字分身</div>
              <div className="desc ellipsis">上传视频, 快速复刻数字分身</div>
              <div className="button">
                快速复刻
                <Arrow />
              </div>
            </div>
          </div>
          <div className="box-title"></div>
        </div>
        {data.map((d) => (
          <div className={`list-box ${openId === d.id ? 'hovered' : ''} `} key={d.id}>
            <div className="box-main" onClick={previewVideo.bind(this, d)}>
              <div
                className="bg"
                style={{
                  backgroundImage: `url(${urlSource(d.video_url, 'video')})`
                }}
              ></div>
              <div className="play">
                <Play />
              </div>
              <div className="op">
                <div className="btn" onClick={toCreateVideo.bind(this, d.id)}>
                  <CreateVideo />
                </div>
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: '1',
                        label: (
                          <div className="dropdown-list" onClick={renameVideo.bind(this, d.id)}>
                            <EditOutlined />
                            重命名
                          </div>
                        )
                      },
                      {
                        key: '2',
                        label: (
                          <div className="dropdown-list" onClick={deleteVideo.bind(this, d.id)}>
                            <DeleteOutlined />
                            删除
                          </div>
                        )
                      }
                    ]
                  }}
                  placement="bottom"
                  open={openId === d.id}
                  onOpenChange={(open: boolean) => setOpenId(open ? d.id : undefined)}
                >
                  <div className="btn" onClick={(e) => e.stopPropagation()}>
                    <Ellipsis />
                  </div>
                </Dropdown>
              </div>
              <div className="photo">
                <img src={urlSource(d.video_url, 'video')} />
              </div>
            </div>
            <div className="bottom">
              {editId === d.id ? (
                <Input
                  value={d.title}
                  autoFocus
                  onChange={onValueChange}
                  onBlur={saveChange.bind(this, d)}
                  onPressEnter={saveChange.bind(this, d)}
                />
              ) : (
                <div className="box-title">{d.title}</div>
              )}
              <div className="btn" onClick={toCreateVideo.bind(this, d.id)}>
                去创作
                <CreateVideo />
              </div>
            </div>
          </div>
        ))}
      </div>
      {globalData.length > 0 && (
        <>
          <div className="common-title">
            <div className="text">公用数字分身（{globalData.length > 0 ? globalData.length : null}）</div>
          </div>
          <div className="page-container">
            {globalData.map((d) => (
              <div className={`list-box ${openId === d.id ? 'hovered' : ''} `} key={d.id}>
                <div className="box-main" onClick={previewVideo.bind(this, d)}>
                  <div
                    className="bg"
                    style={{
                      backgroundImage: `url(${urlSource(d.video_url, 'video')})`
                    }}
                  ></div>
                  <div className="play">
                    <Play />
                  </div>
                  <div className="op">
                    <div className="btn" onClick={toCreateVideo.bind(this, d.id)}>
                      <CreateVideo />
                    </div>
                  </div>
                  <div className="photo">
                    <img src={urlSource(d.video_url, 'video')} />
                  </div>
                </div>
                <div className="bottom">
                  {editId === d.id ? (
                    <Input
                      value={d.title}
                      autoFocus
                      onChange={onGlobalValueChange}
                      onBlur={saveChange.bind(this, d)}
                      onPressEnter={saveChange.bind(this, d)}
                    />
                  ) : (
                    <div className="box-title">{d.title}</div>
                  )}
                  <div className="btn" onClick={toCreateVideo.bind(this, d.id)}>
                    去创作
                    <CreateVideo />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}

      <VideoModal preview={preview} onCancel={() => setPreview(undefined)} btnsShow={true} />

      <CreateAvatar open={createModalOpen} onCancel={() => setCreateModalOpen(false)} onOk={onCreateSuccess} />
    </div>
  )
}

export default memo(Index)
