export const Arrow = (props: any) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      version="1.1"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <defs>
        <clipPath id="master_svg0_411_55036">
          <rect x="0" y="0" width="16" height="16" rx="0" />
        </clipPath>
      </defs>
      <g clipPath="url(#master_svg0_411_55036)">
        <g>
          <path
            d="M6.6208238671875,2.524658203125L6.6208238671875,3.895508203125L11.1471638671875,3.895508203125L2.5067138671875,12.535958203125L3.4668628671875,13.495158203125L12.1063338671875,4.856198203125L12.1063338671875,9.382028203125L13.4787138671875,9.382028203125L13.4787138671875,2.524658203125L6.6208238671875,2.524658203125Z"
            fill="#FFFFFF"
            fillOpacity="1"
          />
        </g>
      </g>
    </svg>
  )
}
