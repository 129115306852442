export const Video = (props: any) => {
  return (
    <svg
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 32 32"
      {...props}
    >
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#4E5666"
          d="M6 5a4 4 0 0 0-4 4v14a4 4 0 0 0 4 4h20a4 4 0 0 0 4-4V9a4 4 0 0 0-4-4H6Zm14.446 9.154c.63.5 1.096 1.07 1.096 1.846s-.466 1.347-1.096 1.846c-.617.49-1.523 1.013-2.651 1.665l-.527.304c-1.129.652-2.034 1.174-2.767 1.464-.748.295-1.475.413-2.147.026-.671-.388-.933-1.077-1.05-1.872-.117-.78-.117-1.825-.117-3.129v-.608c0-1.303 0-2.349.116-3.128.118-.795.38-1.484 1.051-1.872.672-.388 1.4-.27 2.147.026.733.29 1.638.812 2.767 1.464l.527.304c1.128.651 2.034 1.174 2.651 1.664Z"
        ></path>
      </g>
    </svg>
  )
}
