export const Home = (props: any) => {
  return (
    <svg
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 32 32"
      {...props}
    >
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.173 2.401 4.564 9.777A4 4 0 0 0 3 12.95V25a4 4 0 0 0 4 4h18a4 4 0 0 0 4-4V12.95a4 4 0 0 0-1.564-3.173l-9.61-7.376a3 3 0 0 0-3.653 0ZM19 16a3 3 0 0 0-3 3v4.5a1.5 1.5 0 0 0 1.5 1.5h3a1.5 1.5 0 0 0 1.5-1.5V19a3 3 0 0 0-3-3Z"
          fill="#4E5666"
        ></path>
      </g>
    </svg>
  )
}
